import $get from 'lodash.get'
import EventEmitter from 'eventemitter3'

class MediaPlayer extends EventEmitter {
  #content = null
  #episodeIndex = null
  #episodeId = null
  #initialVolumeApplied = 1

  timeUpdateInterval = 100

  get content() {
    return this.#content
  }

  get episode() {
    if (this.content) {
      return this.content.$episode(this.#episodeIndex)
    }

    return null
  }

  get getEpisodeIndex() {
    if (!this.episode) {
      return 0
    }

    const currentEpisodeId = this.episodeId
    let savedIndex = 0
    this.#content.$episodes().forEach((ep, index) => {
      if (ep.id === currentEpisodeId) {
        savedIndex = index
      }
    })

    return savedIndex
  }

  get episodeId() {
    return this.#episodeId || 0
  }

  get episodeIndex() {
    return this.#episodeIndex || 0
  }

  get status() {
    return this.episode && this.episode.status
  }

  get volume() {
    return this.#initialVolumeApplied
  }

  get inActivity() {
    return this.isLoading || this.isPlaying
  }

  get isError() {
    return this.episode && this.episode.isError
  }

  get isLoading() {
    return this.episode && this.episode.isLoading
  }

  get isLoaded() {
    return this.episode && this.episode.isLoaded
  }

  get isPaused() {
    return this.episode && this.episode.isPaused
  }

  get isPlaying() {
    return this.episode && this.episode.isPlaying
  }

  get isStopped() {
    return this.episode && this.episode.isStopped
  }

  get isUnload() {
    return this.episode && this.episode.isUnload
  }

  get isVideo() {
    return this.episode && ['video', 'vimeo'].includes(this.episode.type)
  }

  get player() {
    return this.episode && this.episode.player
  }

  pause() {
    if (this.episode) {
      this.episode.pause()
    }

    return this
  }

  async play() {
    if (this.episode) {
      await this.episode.play()
    }

    return this
  }

  stop() {
    if (this.episode) {
      this.episode.stop()
    }

    return this
  }

  isContent(content, episodeIndex = 0) {
    return (
      content &&
      this.#content &&
      this.#content.id === content.id &&
      this.#episodeIndex === episodeIndex
    )
  }

  isPlayingContent(content, episodeIndex) {
    return this.isPlaying && this.isContent(content, episodeIndex)
  }

  setContent(content, episodeIndex = 0, options) {
    // console.trace('iolo')
    // current media must not load the mediaplayer
    // possible reasons :
    // -> bad formated content
    // -> no audios
    // ! default = undefined
    if (content && content.shouldLoadMediaplayer === false) {
      return this
    }

    // prevent hard reset of content and episode
    // if setContent is called twice or more times
    if (this.isContent(content, episodeIndex)) {
      return this
    }

    const _options = {
      autoPlayNext: true,
      ...options,
    }

    // if a player is running, we unload the previous loaded sources
    // and we stop the currently playing player
    if (this.player) {
      this.stop()
      this.content.$episodes().forEach((ep, epIndex) => {
        // we unload episode only if user wanna load a new content
        // or if given episode index is different
        if (
          content.id !== this.content.id ||
          (content.id === this.content.id && epIndex !== this.#episodeIndex)
        ) {
          ep.unload()
        }
      })
    }

    this.#content = content
    this.#episodeIndex = episodeIndex
    this.#episodeId = $get(this.episode, 'id', null)

    if (this.episode) {
      this.episode.load({
        preload: true,
      })

      this.episode.on('end', () => {
        if (_options.autoPlayNext === true) {
          this.#content.next()
          this.emit('next', {
            index: Math.min(
              this.#episodeIndex,
              this.#content.$episodes().length - 1
            ),
            episode: this.episode,
          })
        }
      })
    }

    return this
  }

  setEpisode(episodeIndex) {
    const episode = this.#content.$episodes(episodeIndex)

    if (episode) {
      this.#episodeIndex = episodeIndex
      this.#episodeId = episode.id

      // todo: refactor - code duplication from setContent
      this.episode.load({
        preload: true,
      })

      this.episode.on('end', () => {
        this.#content.next()
        this.emit('next', {
          index: Math.min(
            this.#episodeIndex,
            this.#content.$episodes().length - 1
          ),
          episode: this.episode,
        })
      })
    }

    return this
  }

  setCurrentTime(time = 0) {
    if (this.episode) {
      this.episode.seek(time)
    }

    return this
  }

  setPercents(percent) {
    const audioDuration =
      this.episode.computedAudioDuration || this.episode.$metadata('duration')
    const time = audioDuration * (percent / 100)

    this.setCurrentTime(time)

    return this
  }

  setVolume(volume) {
    this.#initialVolumeApplied = volume

    if (this.episode) {
      this.episode.volume = volume
    }

    return this
  }
}

export default new MediaPlayer()
