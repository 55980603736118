import $clone from 'lodash.clonedeep'
import $merge from 'lodash.merge'
import { mapState, mapGetters } from 'vuex'

import CArticleViewConversation from '../components/CArticleView/subcomponents/CArticleViewConversation'
import CButtonPlay from '../components/CButtonPlay'
import { computed as contentsComputed } from '../utils/contents'
import WButtonLike from '~/components/WButtonLike'

const DEFAULT_OPTIONS = {
  components: {
    labels: true,
  },
  /**
   * @type {boolean}
   */
  fromProps: true,
  /**
   * @type {function}
   * @example
   * fromStore() {
   *   return this.$store.state.player.content
   * }
   */
  fromStore: undefined,
  /**
   * @type {boolean}
   * load the c-content-labels component (default true)
   */
  withComponentsLabels: true,
}

export default (_options = DEFAULT_OPTIONS) => {
  const options = $merge($clone(DEFAULT_OPTIONS), $clone(_options))

  let additionalComputed = {}
  const additionalComponents = {}

  const props = {
    noAction: {
      type: Boolean,
      default: false,
    },
  }

  if (options.fromProps === true && typeof options.fromStore === 'function') {
    /**
     * @todo(dev)
     * emit a warn here (in dev mode)
     * fromProps should be explicitly false
     */
    options.fromProps = false
  }

  if (options.fromProps === true) {
    props.content = {
      type: Object,
      default: () => ({}),
      required: true,
    }
  }

  if (typeof options.fromStore === 'function') {
    additionalComputed = {
      content() {
        return options.fromStore.call(this)
      },
    }
  }

  additionalComputed = {
    ...additionalComputed,
    ...mapGetters({
      currentEpisode: 'player/currentEpisode',
    }),
  }

  if (options.components.labels) {
    additionalComponents.CContentLabels = () =>
      import(`~/excentrics/shells/bb-default/components/CContentLabels`)
  }

  return {
    components: {
      CArticleViewConversation,
      CButtonPlay,
      WButtonLike,
      ...additionalComponents,
    },
    props,
    computed: {
      ...additionalComputed,

      ...contentsComputed,

      ...mapState({
        currentPlayerContent: (state) => state.player.content,
        currentPlayerEpisodeId: (state) => state.player.episodeId,
      }),
    },
    methods: {
      isActiveAndVisibleVideo(video) {
        return video.id === this.currentPlayerEpisodeId
      },
      handleAddLike() {
        this.$store.dispatch('contents/react', { content: this.content })
      },
      handleRemoveLike() {
        this.$store.dispatch('contents/unreact', { content: this.content })
      },
      handleSelect() {
        const content = this.content

        if (this.noAction) {
          return
        }

        // will autoplay media
        this.$store.dispatch('player/changePlayerContent', {
          content,
        })

        if (this.$voicer.getConfig('disableAutoplay') === false) {
          if (this.isPlayingContent) {
            this.$store.dispatch('player/ctrlPlayer', 'pause')
          }

          this.$store.dispatch('player/ctrlPlayer', {
            action: 'play',
            content,
          })
        }
      },
    },
  }
}
