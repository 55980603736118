/**
 * @api public
 * @class
 * Events
 *
 * @description
 * Provide a simple function called `emit` which
 * accumulate and per batch
 */

class EventManager {
  /**
   *
   * @param {string} name
   * @param {object} data
   * @returns {Promise}
   */
  emit(name, data) {
    let dataraw

    if (this.__ready === false) {
      // spoke not ready, prevent anonymous call
      console.warn(`[out_call] spoke is not ready`)
      return Promise.resolve()
    }

    try {
      if (data) {
        dataraw = Buffer.from(JSON.stringify(data))
      }

      return this.__http.post(`/events`, {
        name,
        data: dataraw.toString('base64'),
      })
    } catch (error) {
      // @todo
      // develop a retry strategy here and on analytics events
      console.warn(`Unprocessable eventmanager data payload`)
      console.warn({ data })
      console.warn(error)
    }
  }
}

export default new EventManager()
