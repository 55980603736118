export default function (context) {
  const { $spoke, route, store } = context
  const { mode = 'default' } = context.route.query

  if (route.name === 'index') {
    store.dispatch('root/setCurrentPage', mode)
  } else {
    store.dispatch('root/setCurrentPage', context.route.name)
  }

  // here, we will suspend the video if the user leaves the page
  if (store.state.player.status === 'play') {
    if ($spoke.mediaplayer.isVideo) {
      $spoke.mediaplayer.stop()
    }
  }
}
