import $clone from 'lodash.clone'
import $merge from 'lodash.merge'
import VimeoPlayer from '@vimeo/player'

import MediaPlayer from '../../services/MediaPlayer'
import CommonMedia from './Media'

// note(dev):
// `play` must be omitted here
// there is some case where howler
// will call `play` multiple times
// a trick is made in `load` to prevent
// multiple invocations
const VIDEO_EVENTS = ['ended', 'playing', 'pause', 'seeking', 'seeked']

/**
 * @class Video
 * @extends Model
 * @description
 * Video model
 *
 * @todo
 * implement play method
 * User:play -> Server:authentify & generate tmp token -> User:fetch media with token
 *
 * @todo
 * should be commented (good first commit)
 */
const DEFAULT_MODEL = {
  value: '',
  type: 'video',
  metadatas: {
    duration: 0,
    position: 0,
    title: 'Unknown',
  },
}

class CommonVideo extends CommonMedia {
  static modelName = 'Video'
  static modelDefaults = DEFAULT_MODEL

  /**
   * @api private
   */
  #vimeoBooted = false

  /**
   * @api private
   * @description
   * attached content (yes, it's a circular reference)
   */
  #content = null

  /**
   * @api private
   * @description
   * episode index (array  index in the parent content epideos property)
   */
  #episodeIndex = 0

  /**
   * @api private
   * @see load method
   * @see VimeoPlayer
   */
  #player = null

  /**
   * @api public
   * @description
   * status of the current player
   * can have the following values
   * - unload
   * - loading
   * - load
   * - error
   * - pause
   * - play
   * - stop
   * default value is `unload`
   */
  status = 'unload'

  constructor(data, content, index) {
    super($merge({}, DEFAULT_MODEL, data))

    this.#content = content
    this.#episodeIndex = index
  }

  static resource = 'videos'

  get content() {
    return this.#content
  }

  get currentTime() {
    return this.#player ? this.#player.seek() : 0
  }

  get episodeIndex() {
    return this.#episodeIndex
  }

  get duration() {
    return this.$metadata('duration', 0)
  }

  get isPaused() {
    return this.status === 'pause'
  }

  get isPlaying() {
    return this.status === 'play'
  }

  get isStopped() {
    return this.status === 'stop'
  }

  get isAudio() {
    return false
  }

  get isVideo() {
    return true
  }

  get playerInstance() {
    return this.#player
  }

  get volume() {
    if (this.#player) {
      return this.#player.getVolume()
    }
    return 0
  }

  set volume(v) {
    if (this.#player) {
      return this.#player.setVolume(v)
    }
  }

  // ! important notice
  // on media models, the load method is agnostic and will be invoked no matter what happens by
  // the Mediaplayer when initializing a content.
  // Except that it should not lead to specific actions in the case of vimeo files,
  // the load must be initialized once the video component is mounted
  // @see excentrics/shells/bb-default/components/CVimeoPlayerCard/index.vue
  // however, another "explicitLoad" method is defined below and will be invoked by the component.
  load(options) {
    // noop
    return Promise.resolve()
  }

  // @see "load" comment to understand why I need this
  explicitLoad(element, options = {}) {
    const propagateEvent = (eventName, context) => (data) =>
      context.emit(eventName, data)
    const dispatchEvent = (eventName) => (data) => {
      propagateEvent(eventName, this)(data)
      propagateEvent(
        eventName,
        MediaPlayer
      )({ content: this.#content, ...data })
    }

    dispatchEvent('loading')()

    let source = $clone(this.data.value)
    source = source.replace('https://player.vimeo.com/video/', '')
    source = source.replace('https://vimeo.com/', '')

    // eslint-disable-next-line
    this.#player = new VimeoPlayer(element, {
      id: source,
      controls: true,
      responsive: true,
      ...options,
    })

    VIDEO_EVENTS.forEach((eventName) => {
      this.#player.on(eventName, dispatchEvent(eventName))
    })

    this.#player.on('loaded', () => {
      // yeah, fuck you Howler
      dispatchEvent('load')()
      dispatchEvent('loaded')()
    })

    this.#player.on('play', () => {
      if (this.status !== 'play') {
        dispatchEvent('play')()
        this.status = 'play'
      }
    })

    this.#player.on('timeupdate', (data) => {
      dispatchEvent('timeupdate')({
        ...data,
        time: data.seconds, // standard
      })
    })

    return this
  }

  pause() {
    if (this.#player) {
      this.status = 'pause'
      this.#player.pause()
    }

    return this
  }

  async play() {
    if (this.#player) {
      await this.#player.play()
    }

    return this
  }

  seek(time) {
    setTimeout(() => {
      if (this.#player) {
        // const savedVolume = this.volume

        this.status = 'loading'
        this.emit('loading')
        this.#player.setCurrentTime(time)

        setTimeout(() => {
          // vimeo player issue
          // when we move the current time, volume is internally set at 0
          try {
            this.volume = this.#player.getVolume()
          } catch (e) {}
        }, 30)
      }
    }, 20)

    return this
  }

  stop() {
    if (this.#player) {
      this.status = 'stop'
      this.#player.pause()
      this.#player.setCurrentTime(0)
    }

    return this
  }

  unload() {
    console.warn('unload a vimeo instance is not possible')

    return this
  }
}

/**
 * ensure data is well formated (API-LTS compliant)
 * @param {object} data
 * @returns {boolean}
 */
CommonVideo.isValid = function (data) {
  return true
}

export default CommonVideo
