<template>
  <div class="w-volume-controller">
    <v-card flat width="110" elevation="0">
      <v-slider v-model="volume" color="grey lighten-1" />
    </v-card>
    <v-btn icon @click="handleRapidCut">
      <v-icon v-if="volume > 0" color="grey lighten-1">
        mdi-volume-medium
      </v-icon>
      <v-icon v-else color="grey lighten-1"> mdi-volume-off </v-icon>
    </v-btn>
  </div>
</template>

<script>
import spoke from '~/utils/spoke'

export default {
  data() {
    return {
      volume: 100,
    }
  },
  watch: {
    volume(value) {
      spoke.mediaplayer.setVolume(value / 100)
    },
  },
  methods: {
    handleRapidCut() {
      if (this.volume > 0) {
        this.volume = 0
      } else {
        this.volume = 50
      }
    },
  },
}
</script>

<style lang="scss">
.w-volume-controller {
  display: flex;
  align-items: center;

  .v-input {
    &--slider {
      margin-top: 0;
    }

    &__control {
      .v-messages {
        display: none;
      }
    }

    &__slot {
      margin-bottom: 0;
    }
  }
}
</style>
