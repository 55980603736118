export default {
  bold: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'primary',
  },
  colorIcon: {
    type: String,
    default: undefined,
  },
  depressed: {
    type: Boolean,
    default: false,
  },
  fab: {
    type: Boolean,
    default: false,
  },
  flat: {
    type: Boolean,
    default: false,
  },
  noRedirection: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'normal',
  },
  showDuration: {
    type: Boolean,
    default: false,
  },
  useContent: {
    type: Object,
    default: null,
  },
  variant: {
    type: String,
    default: 'icon',
  },
}
