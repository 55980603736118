module.exports = function (
  context,
  route,
  { hasNoPlayerContent, hasContents }
) {
  context.dispatch('player/forceOpenPersistantPlayer', null, { root: true })

  switch (route.slug) {
    case 'default':
      // eslint-disable-next-line no-case-declarations
      const defContents = context.state.contents.default[3]

      // sort contents by featured & position
      // select first as default content
      // eslint-disable-next-line no-case-declarations
      const sorted = defContents.sort((a, b) => {
        if (a.isFeatured === false && b.isFeatured) {
          return 1
        } else if (a.isFeatured === true && b.isFeatured === false) {
          return -1
        } else {
          if (a.metadatas.position < b.metadatas.position) return -1
          else if (a.metadatas.position > b.metadatas.position) return 1
          return 0
        }
      })

      if (hasNoPlayerContent && sorted.length) {
        context.dispatch('player/setPlayerContent', sorted[0], {
          root: true,
        })
      }

      break
    case 'chronicles':
    case 'episodes':
      // eslint-disable-next-line no-case-declarations
      const epContents = context.state.contents[route.slug][3]

      if (hasNoPlayerContent && hasContents) {
        context.dispatch('player/setPlayerContent', epContents[0], {
          root: true,
        })
      }
      break
  }

  return Promise.resolve()
}
