export const RESET_INITIAL_STATE = `reset initial state`
export const SET_CONTENT = `set content`
export const SET_EPISODE_INDEX = `set episode index`
export const SET_EPISODE_ID = `set episode id`
export const SET_INSTALLED = `set installed`
export const SET_LOADER = `set loading`
export const SET_PERSISTANT_VISIBLE = `set persistant visible`
export const SET_STATUS = `set status`
export const SET_CURRENT_MODE = 'set current mode'

export default {
  RESET_INITIAL_STATE,
  SET_CURRENT_MODE,
  SET_CONTENT,
  SET_EPISODE_INDEX,
  SET_EPISODE_ID,
  SET_INSTALLED,
  SET_LOADER,
  SET_PERSISTANT_VISIBLE,
  SET_STATUS,
}
