import { format, parseISO } from 'date-fns'
import { fr, enGB, pl, pt } from 'date-fns/locale'

const LOCALES = {
  en: enGB,
  fr,
  pl,
  pt,
}

export const configureLocales = (locales) => {
  locales.forEach((locale) => {
    LOCALES[locale.code] = require('date-fns/locale')[locale.momentLocale]
  })
}

export const formatDate = (date, locale) => {
  return format(typeof date === 'string' ? parseISO(date) : date, `d LLL yyy`, {
    locale: LOCALES[locale],
  })
}

export const formatDateHour = (date, locale) => {
  return format(
    typeof date === 'string' ? parseISO(date) : date,
    `d LLL yyy 'à' k:mm:ss`,
    {
      locale: LOCALES[locale],
    }
  )
}
