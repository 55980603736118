import $clone from 'lodash.clonedeep'
import $get from 'lodash.get'
import humanizeDuration from 'humanize-duration'

import { formatDate } from '~/utils/dates'
import { $metadata } from '~/utils/spoke'

function genMediaImageOutput(media) {
  const originSrc = $get(media, 'value', media)
  const cdnSrc = $get(media.metadatas, 'res_base.value', originSrc)
  const lazySrc = $get(media.metadatas, 'res_strict.value', originSrc)

  return {
    cdnSrc,
    originSrc,
    lazySrc: `data:image/jpeg;base64,${lazySrc}`,
    source: originSrc,
  }
}

function getMediasImages(metadatas, slug) {
  const DEFAULT_IMAGE_OBJECT = {
    cdnSrc: '',
    originSrc: '',
    lazySrc: 'data:image/jpeg;base64,',
    exists: false,
    source: '',
  }

  if (!metadatas) {
    return DEFAULT_IMAGE_OBJECT
  }

  const metadata = $clone(metadatas[slug])

  if (!metadata) return DEFAULT_IMAGE_OBJECT

  if (Array.isArray(metadata)) {
    return metadata.map((m) => {
      return genMediaImageOutput(m)
    })
  } else {
    return genMediaImageOutput(metadata)
  }
}

export const computed = {
  /**
   * @type {array}
   * return documents and links as flatten array
   */
  attachments() {
    return [...this.documents, ...this.links]
  },

  /**
   * @type {object}
   * return the content conversation module
   */
  conversation() {
    return this.content.conversations.find((conv) => conv.type === 'comment')
  },

  /**
   * @type {number}
   * return the number of messages in the main conversation feed
   */
  conversationComments() {
    const conversation = this.conversation

    return conversation ? $get(conversation, 'stats.comments', 0) : 0
  },

  /**
   * @type {string}
   * current playing episode title
   */
  currentEpisodeTitle() {
    const defaultValue = this.isLive ? `Direct` : `Episode 1`

    return $metadata(this.currentEpisode, 'title', defaultValue)
  },

  /**
   * @type {array}
   * list of associed documents
   */
  documents() {
    return $get(this.metadatas, 'documents', [])
  },

  /**
   * @type {string}
   * total media duration
   */
  duration() {
    const duration = this.episodes.reduce((prev, episode) => {
      return prev + $get(episode, 'metadatas.duration', 0)
    }, 0)

    return humanizeDuration(duration * 1000, {
      round: true,
      language: 'fr',
      units: ['m'],
    })
  },

  /**
   * @type {array}
   * list of content episodes
   */
  episodes() {
    return $get(this.content, 'episodes', [])
  },

  /**
   * @type {string}
   * the content full text
   */
  fullcontent() {
    return $get(this.content, 'content')
  },

  /**
   * @returns {string}
   */
  headline() {
    return this.metadatas.headline
  },

  /**
   * @type {string}
   */
  id() {
    return $get(this.content, 'id')
  },

  /**
   * @type {object}
   * @returns {object} out.card
   * @returns {object} out.cards
   * @returns {object} out.heading
   * @returns {object} out.thumbnail
   */
  images() {
    let cards = getMediasImages(this.metadatas, 'cards', [])
    let heading = getMediasImages(this.metadatas, 'heading')
    let thumbnail = getMediasImages(this.metadatas, 'thumbnail')

    if (!Array.isArray(cards) && typeof cards === 'object') {
      cards = [cards]
    } else if (cards.length === 0) {
      // ! @todo: no cards : set a placeholder
      cards = [
        {
          lazySrc: '',
          cdnSrc: '',
          source: '', // the cdnSrc || metadata.value (the original source)
          // why ? because some contents can be visible before
          // the procedural image resizing task (service jobs fn)
        },
      ]
    }

    if (thumbnail.exists === false) {
      thumbnail = cards[0]
    }
    if (heading.exists === false) {
      heading = cards[0]
    }

    return {
      card: cards[0],
      cards,
      heading,
      thumbnail,
    }
  },

  /**
   * @type {Array<string>}
   * @returns list of cards images
   */
  mappedCards() {
    return [
      ...this.images.cards.map((img) => {
        return img.cdnSrc
      }),
    ]
  },

  /**
   * @type {object}
   * @returns content metadatas
   */
  metadatas() {
    return this.content.metadatas
  },

  /**
   * @type {array}
   * list of associed links
   */
  links() {
    return $get(this.metadatas, 'links', [])
  },

  /**
   * @type {string}
   * @returns content publication date
   */
  publishedAt() {
    const date = new Date($get(this.content, 'publishedAt', new Date()))

    return formatDate(date, this.$i18n.locale)
  },

  /**
   * @type {string}
   * a short description of the content
   * should be less than 167(+3) chars
   */
  summary() {
    let summary = $get(this.content, 'description', '')

    if (!summary) {
      summary = $get(this.content, 'content', '')
    }

    summary = summary.replace(/\n/gi, '')

    return summary.length > 100 ? summary.substr(0, 97) + '...' : summary
  },

  /**
   * @type {array}
   */
  tags() {
    return this.content.tags
  },

  /**
   * @type {string}
   * the content title
   */
  title() {
    return this.content.name
  },

  /**
   * @type {string}
   * the content title, but tiny (automatically wrapped)
   */
  tinyTitle() {
    if (this.title.length < 67) {
      return this.title
    }
    return this.title.substr(0, 67) + '...'
  },

  /**
   * @type {string}
   * the content type
   */
  type() {
    return this.content.type
  },

  /**
   * @type {array}
   * list of content videos
   */
  videos() {
    return $get(this.content, 'videos', [])
  },

  /**
   * ----
   * @scope is properties
   * ----
   */

  /**
   * @type {boolean}
   * for announcement, par exemple
   */
  isLayoutLarge() {
    return this.metadatas['layout-large'] === true
  },

  /**
   * @type {boolean}
   * true if the current content is a live content
   */
  isLive() {
    return this.type === 'live'
  },

  /**
   * @type {boolean}
   * true if this content is currently loading
   */
  isMediaLoading() {
    return (
      this.$store.state.player.content.id === this.id &&
      this.$store.state.player.status === 'loading'
    )
  },

  /**
   * @type {boolean}
   * true if this content is currently played
   */
  isMediaPlaying() {
    return (
      this.$store.state.player.content.id === this.id &&
      this.$store.state.player.status === 'play'
    )
  },

  /**
   * @type {boolean}
   */
  isMedia() {
    return ['live', 'podcast', 'video', 'vimeo'].includes(this.type)
  },

  /**
   * @type {boolean}
   */
  isReacted() {
    return $get(this.content, 'reactions.isReacted')
  },

  /**
   * @scope has properties
   */
  // ----
  /**
   * @type {boolean}
   * return true if content has a conversation module
   * AND if this conversation module is active
   */
  hasActiveConversationModule() {
    return this.hasConversationModule && this.conversation.active === true
  },

  /**
   * @type {boolean}
   * true if the current content contains docs or links
   */
  hasDocumentsOrLinks() {
    const documents = $get(this.metadatas, 'documents', [])
    const links = $get(this.metadatas, 'links', [])

    return documents.length || links.length
  },

  /**
   * @type {boolean}
   * true if the current content has a comment (conversation) system
   */
  hasConversationModule() {
    return this.content.conversations.some((conv) => conv.type === 'comment')
  },

  /**
   * @type {boolean}
   * true if the current content has more than 1 episode
   */
  hasEpisodes() {
    return this.episodes.length > 0
  },

  /**
   * @type {boolean}
   * true if the current content has a headline metadata
   */
  hasHeadline() {
    return $get(this.metadatas, 'headline', '').length > 0
  },

  /**
   * True if the title has more then 24 chars
   * @returns {boolean}
   */
  hasLongTitle() {
    return this.title.length > 24
  },
}
