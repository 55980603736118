import Vue from 'vue'
import {
  VueFlux,
  FluxCaption,
  FluxControls,
  FluxIndex,
  FluxPagination,
  FluxPreloader,
} from 'vue-flux'

import 'vue-flux/dist-ssr/vue-flux.css'

const components = {
  VueFlux,
  FluxCaption,
  FluxControls,
  FluxIndex,
  FluxPagination,
  FluxPreloader,
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
