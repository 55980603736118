/**
 * configuration applied when BROCOLI_API_MODE = production
 */
module.exports = {
  authMode: ['providers'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'auchan',
        clientId: 'vi-auchan',
        buttonText: 'Connexion Auchan',
      },
    ],
  ],
}
