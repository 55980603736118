import CommonTag from '../commons/Tag'

export default class DocumentTag extends CommonTag {
  $rehydratation(data) {
    if (data.tags) {
      data.tags = data.tags.map((tag) => new DocumentTag(tag))
    }
    return super.$rehydratation(data)
  }
}
