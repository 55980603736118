import jscookie from 'js-cookie'

class LocalDB {
  all() {
    return jscookie.get()
  }

  get(path, defaultValue) {
    const data = jscookie.get(path)

    if (!data) {
      return defaultValue
    }

    return data
  }

  set(...args) {
    return jscookie.set(...args)
  }

  storeToken(token) {
    return this.set('spoke_stok', token, {
      expires: 365,
      sameSite: 'strict',
      secure: process.env.NODE_ENV === 'production',
    })
  }

  remove(...args) {
    return jscookie.remove(...args)
  }
}

export default new LocalDB()
