import $clone from 'lodash.clonedeep'
import $get from 'lodash.get'
import { mapState, mapGetters } from 'vuex'

import { computed as contentsComputed } from '../../utils/contents'
import specificProps from './_props'

export default {
  props: {
    ...specificProps,
  },

  computed: {
    content() {
      if (this.useContent) {
        return $clone(this.useContent)
      }

      return $clone(this.$store.state.player.content)
    },
    ...contentsComputed,
    ...mapState({
      playerContentId: (state) => state.player.content.id,
    }),
    ...mapGetters({
      isError: 'player/isError',
      isLoading: 'player/isLoading',
      isPlaying: 'player/isPlaying',
    }),
    isSameContent() {
      const content = this.content

      return $get(content, 'id') === this.playerContentId
    },
    isLoadingContent() {
      return this.isSameContent && this.isLoading === true
    },
    isErrorContent() {
      return this.isSameContent && this.error
    },
    isPlayingContent() {
      return this.isSameContent && this.isLoading === false && this.isPlaying
    },
    hasText() {
      return this.variant.includes('text')
    },
    showIcon() {
      return this.variant.includes('icon')
    },
  },

  methods: {
    handleClick() {
      if (this.$voicer.isMobile) {
        this.$store.dispatch('player/openPersistantPlayer')
      }
      if (this.isPlayingContent) {
        this.$store.dispatch('player/ctrlPlayer', 'pause')
      } else {
        this.$store.dispatch('player/changePlayerContent', {
          content: this.content,
          noRedirect: this.noRedirection,
        })
        this.$store.dispatch('player/ctrlPlayer', {
          action: 'play',
          content: this.content,
        })
      }
    },
  },
}
