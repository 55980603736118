<template>
  <v-container
    class="b-footer mt-4"
    :class="{
      'pa-4': !noPadding && $voicer.isDesktopOrTablet,
      'pa-0': noPadding,
      ['b-footer--light']: $voicer.isLightTheme,
      ['b-footer--dark']: $voicer.isDarkTheme,
    }"
  >
    <v-divider class="b-footer__divider mb-3 mt-2" />
    <w-block name="footer" />
    <div class="caption">&copy; {{ $t('cp_footer_company') }}</div>
    <div class="caption mt-1">{{ $t('cp_footer_companysubtitle') }}</div>
    <div class="mt-2 caption b-footer__links">
      <b-link to="/data/terms-of-service" minor>
        {{ $t('cp_footer_link_terms') }}
      </b-link>
      <b-link to="/data/legal-notice" minor>
        {{ $t('cp_footer_link_legals') }}
      </b-link>
      <b-link to="/data/personal-data" minor>
        {{ $t('cp_footer_link_data') }}
      </b-link>
    </div>
    <div class="b-footer__usefull mt-2 pt-2 pb-2">
      <div class="is-link caption font-weight-bold" @click="switchLang = true">
        <v-icon small>mdi-translate</v-icon>
        {{ $t('cp_footer_lang_title') }}
      </div>
      <b-link
        v-if="$route.name !== 'settings'"
        to="/settings"
        minor
        class="caption font-weight-bold"
      >
        <v-icon small>mdi-cog</v-icon>
        {{ $t('cp_footer_link_settings') }}
      </b-link>
      <b-link
        v-if="$route.name !== 'support'"
        to="/support"
        minor
        primary
        class="caption font-weight-bold"
      >
        <v-icon small>mdi-face-agent</v-icon>
        {{ $t('cp_footer_link_needsupport') }}
      </b-link>
    </div>
    <div class="mt-2 caption b-footer__version">Version {{ version }}</div>
    <b-lang-switcher v-model="switchLang" @close="switchLang = false" />
  </v-container>
</template>

<script>
import pkg from '~/package.json'
import BLangSwitcher from '~/components/BLangSwitcher'

export default {
  components: {
    BLangSwitcher,
  },
  props: {
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      switchLang: false,
    }
  },
  computed: {
    version() {
      return pkg.version
    },
  },
}
</script>

<style lang="scss">
.b-footer {
  &--light {
    color: rgba(175, 175, 175, 0.86) !important;
  }
  &--dark {
    color: rgba(65, 65, 65, 0.86) !important;
  }

  &__divider {
    max-width: 100%;
    width: 40px;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;

    & > *:not(:last-child) {
      margin-right: 10px;
      line-height: 1.55em;
    }
  }

  &__usefull {
    display: flex;
    flex-wrap: wrap;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  // todo: fix html, ugly
  .is-link {
    color: rgba(175, 175, 175, 0.86);
    border-bottom: 1px rgba(175, 175, 175, 0.86) solid;
    cursor: pointer;
  }
}
</style>
