<template>
  <div
    class="b-section-title"
    :class="{
      'b-section-title--important': important,
      'b-section-title--large': large,
      'b-section-title--mobile': $voicer.isMobile,
    }"
  >
    <div
      class="b-section-title__wrapper"
      :class="{ 'mt-2': $voicer.isDesktopOrTablet, 'mt-4': $voicer.isMobile }"
    >
      <div
        class="b-section-title__message text-alternate caption font-weight-medium grey--text text--lighten-1"
      >
        <slot />
        <div class="borderlines">
          <b-borderline color="grey lighten-2" height="4px" />
          <b-borderline color="grey lighten-2" height="4px" />
          <b-borderline color="grey lighten-2" height="4px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: undefined,
    },
    important: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.b-section-title {
  $c: &;

  display: flex;
  justify-items: center;
  position: relative;
  z-index: 1;

  &--large {
    padding-bottom: 24px;
    padding-top: 0px;
  }

  &--important {
    #{$c}__wrapper {
      padding: 10px;
    }
  }

  &--mobile {
    padding-bottom: 6px;
  }

  &__wrapper {
    display: inline-flex;
    margin: 0 auto;
    justify-content: center;

    .caption {
      text-align: center;
    }
  }

  .borderlines {
    margin: 0 22px;
    text-align: center;
    margin-top: 8px;

    .b-borderline {
      display: inline-block;
      width: 4px;
      margin: 0 4px;

      &__line {
        border-radius: 50%;
      }
    }
  }
}
</style>
